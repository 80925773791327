import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState,useMemo,  useCallback, } from "react";
import { StyledTableCell, useStyles } from "./ReportsTable.style";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./ReportsTable.css";


function ReportsTableView(props) {
  
  const {
    headers,
    tableData,
    handleSorting,
    sortedField,
    tableContainerSx,
    component,
    fullView = null,
  } = props;


  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      if(component === "systemLog" && (params.node.data.Action === "Bought" || params.node.data.Action ===  "Sold")){
        return { background: "#1D4336" };
      }else {
        return { backgroundColor: "#222222 " };
      } 
    } else {
      if(component === "systemLog" && (params.node.data.Action === "Bought" || params.node.data.Action ===  "Sold")){
        return { background: "#1D4336" };
      }else {
        return { backgroundColor: "#2d2d2d" };
      } 
     
    }
    
  
  };

  const gridOptions = {
     defaultColDef: {
      cellStyle: { 
        fontSize: "13px",
        color: "#ffffff",
        textAlign: "left",
        borderBottom: "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  };

 
  const classes = useStyles();
  return (
    <Grid
      className={`ag-theme-quartz ${classes.tableStyle}`}
      style={{ width:"100%",border:"1px solid rgb(61, 63, 71)" }}
      sx={tableContainerSx ? tableContainerSx : null}
    >
      <AgGridReact
        rowData={tableData}
        columnDefs={headers}
        gridOptions={gridOptions}
        getRowStyle={getRowStyle}
      />
    </Grid>
  );
}


export default ReportsTableView;
